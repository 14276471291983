import React, { useCallback } from 'react'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { hooks } from '@front/volcanion'
import { Box } from '@mui/material'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const IncidentHistoryList = props => {
  const [codeIntlPhone] = hooks.useSetting('code_international_phone')

  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(codeIntlPhone), [codeIntlPhone])

  return (
    <Box className='safearea'>
      <SearchFrame
        model_name={'event'}
        populate={['order']}
        allowCreate={false}
      >
        <SearchForm
          filterToForm={filterToForm}
          formToFilter={formToFilter}
          submitOnMount
        >
          <FormContent><Form /></FormContent>
        </SearchForm>
        <SearchTable
          tableProps={{
            onRowClick: null,
            stickyHeader: true,
            enableRowActions: true,
            enableGlobalActions: false,
            enableToolbar: true,
            enablePagination: true,
            allowEditMode: false,
            allowRowGenerate: false,
          }}
        >
          <TableHeader><Header /></TableHeader>
          <TableRow><Row /></TableRow>
        </SearchTable>
      </SearchFrame>
    </Box>
  )
}

export default React.memo(IncidentHistoryList)
