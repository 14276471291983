import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell><TextHeader label={I18n.t('incident.type')} /></TableCell>
  <TableCell><TextHeader label={I18n.t('date.label', { count: 1 })} /></TableCell>
  <TableCell><TextHeader label={I18n.t('driver.label', { count: 1 })} /></TableCell>
  <TableCell><TextHeader label={I18n.t('user.label', { count: 1 })} /></TableCell>
  <TableCell><TextHeader label={I18n.t('order.client_id')} /></TableCell>
  <TableCell><TextHeader label={I18n.t('status.label', { count: 1 })} /></TableCell>
  <TableCell><TextHeader label={I18n.t('processed.date')} /></TableCell>
  <TableCell><TextHeader label={I18n.t('processed.time')} /></TableCell>
  <TableCell><TextHeader label={I18n.t('operator.label', { count: 1 })} /></TableCell>
</>

export default React.memo(Header)
